<template>
  <div>
    <div class="mb-2">
      <b-row>
        <b-col md="4">
          <b-button
            variant="flat-secondary"
            class="btn-icon mr-1"
            @click="$router.push({ name: `${self.usertype}-image-library` })"
          >
            <feather-icon
              size="20"
              icon="ArrowLeftIcon"
            />
            <span class="ml-1">{{ $t('actions.back') }}</span>
          </b-button>
          <b-button v-b-tooltip.hover="$t('assets-module.add-assets')"
                    variant="outline-primary"
                    @click="toggleAssetModal"
          >
            <feather-icon icon="PlusIcon" />{{ $t('assets-module.add-assets') }}
          </b-button>
        </b-col>
        <b-col md="8">
          <p class="text-muted"
             style="font-size:13px; font-style: italic;"
          >
            <feather-icon icon="InfoIcon" />
            {{ $t('assets-module.info') }}
          </p>
        </b-col>
      </b-row>

    </div>
    <b-row class="mb-2">
      <b-col md="4">
        <b-form-input v-model="searchName"
                      :placeholder="$t('assets-module.search-asset')"
                      debounce="800"
                      @update="getAssets()"
        />
      </b-col>
    </b-row>
    <b-overlay :show="isProcessing">
      <b-row>
        <b-col v-for="(asset, index) in assets"
               :key="index"
               md="3"
        >
          <asset-card :item="asset"
                      @assetUpdate="getAssets"
                      @onDeleteAsset="value => assetToDelete = value"
          />
        </b-col>
      </b-row>
    </b-overlay>
    <Pagination v-if="assets.length >0"
                :pagination="paginationData"
                :is-processing="isProcessing"
                @onPaginationChange="page => getAssets(searchName, page) "
    />
    <b-modal
      id="modal-add-asset"
      ref="modal-add-asset"
      v-model="show"
      ok-title="Upload"
      no-close-on-backdrop
      content-class="shadow"
      ok-variant="primary"
      :title="$t('assets-module.upload-assets')"
      no-close-on-esc
      modal-class="modal-primary"
      centered
      :click-to-close="false"
      size="lg"
    >
      <file-uploader v-model="assetFiles" />
      <template #modal-footer="{ }">
        <b-row>
          <b-col
            md="12"
            class="d-flex justify-content-end"
          >
            <b-button
              variant="primary"
              :disabled="isProcessing || assetFiles.length == 0"
              class="ml-1"
              @click="uploadAsset"
            >
              <b-spinner
                v-show="isProcessing"
                small
              />
              {{ $t('actions.upload') }}
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
    <DeleteModal :title="$t('delete')"
                 :show="!!assetToDelete"
                 :sub-title="$t('delete-confirmation')"
                 :on-delete="handleDelete"
                 :is-processing-delete="isDeleting"
                 @close="assetToDelete = null"
    />
  </div>
</template>

<script>
import {
  BButton, BOverlay, BModal, BRow, BCol, BSpinner, BFormInput,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { getUserData } from '@/auth/utils'
import DeleteModal from '@/views/common/components/DeleteModal.vue'
import Pagination from '@/views/common/components/Pagination.vue'
import FileUploader from '@/views/common/components/FileUploader.vue'
import AssetCard from './components/AssetCard.vue'

export default {
  components: {
    BButton,
    BOverlay,
    Pagination,
    BModal,
    FileUploader,
    BRow,
    BCol,
    AssetCard,
    DeleteModal,
    BSpinner,
    BFormInput,
  },
  data() {
    return {
      assets: [],
      isProcessing: false,
      isDeleting: false,
      paginationData: {},
      searchName: '',
      assetFiles: [],
      inputData: {},
      assetToDelete: null,
      show: false,
      self: getUserData(),
    }
  },
  created() {
    this.getAssets()
  },
  methods: {
    toggleAssetModal() {
      this.assetFiles = []
      this.show = true
    },
    getAssets(name = this.searchName, page = 1) {
      this.isProcessing = true
      useJwt.getAssets({
        params: {
          name,
          page,
        },
      }).then(response => {
        this.assets = response.data?.data?.data
        this.paginationData = {
          current_page: response.data.data.current_page,
          per_page: response.data.data.per_page,
          total: response.data.data.total,
          last_page: response.data.data.last_page,
        }
      }).finally(() => {
        this.isProcessing = false
      })
    },
    uploadAsset() {
      this.isProcessing = true
      const dataUrl = this.assetFiles
      const formData = new FormData()
      dataUrl.forEach(file => {
        formData.append('files[]', file.data)
        formData.append('descriptions[]', file.description)
        formData.append('filenames[]', file.filename)
      })

      useJwt.storeAssets(formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          this.showSuccessMessage(response)
          this.show = false
          this.getAssets()
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
        .finally(() => {
          this.isProcessing = false
          this.inputData = {}
        })
    },
    handleDelete() {
      this.isDeleting = true
      useJwt.deleteAsset(this.assetToDelete.id).then(response => {
        this.assetToDelete = null
        this.showSuccessMessage(response)
        this.getAssets()
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isDeleting = false
      })
    },
  },
}
</script>

<style scoped>

</style>
